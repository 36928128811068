import React from "react";
import { Slide, Fade } from "react-slideshow-image";
import 'react-slideshow-image/dist/styles.css';
import "./slideshow_fullscreen.css";
const images = require.context("./images", true);
const imageList = images.keys().map((image) => images(image));

const Slideshow_fullscreen = () => {

  return (
    <div>
      <div className="container">
        <Fade
          autoplay
          duration={5000}
          transitionDuration={1000}
          indicators={false}
          arrows={false}
          canSwipe={true}
          pauseOnHover={false}
          easing="ease"
        >
          {imageList.map((image, index) => (
            <div key={index} className="slide">
              <img src={image} alt={`Slide ${index}`} />
            </div>
          ))}
        </Fade>
      </div>
    </div>
  );
};
export default Slideshow_fullscreen;
