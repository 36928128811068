import React, { Component } from 'react';
import Slideshow from './slideshow';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
// import './App.css'
import Slideshow_fullscreen  from './slideshow_fullscreen';
class App extends Component{
  constructor() {
    super();
    this.state = {
      flipflop: true
    }

    setTimeout(() => {
      window.location.reload();
    }, 1800000);
  }
  headerContent = 'Information about Upcoming Events';
  footerContent = <p>&copy; {new Date().getFullYear()} Pingstkyrkan. All rights reserved.</p>;
  render() {
    return (
      <>
        <button onClick={() => {
          this.setState({ flipflop: !this.state.flipflop });
        }} style={{ position: 'fixed', bottom: '5px', right:'5px', zIndex: 999}}>Byt vy</button>
        {
          this.state.flipflop ?  
          <Slideshow header={this.headerContent} footer={this.footerContent} />
          :
          <Slideshow_fullscreen />     
        }
      </>
    )
  }
}
export default App;