import React from "react";
import { Fade } from "react-slideshow-image";
import 'react-slideshow-image/dist/styles.css';
import "./Slideshow.css";
const images = require.context("./images", true);
const imageList = images.keys().map((image) => images(image));

const Slideshow = ({ header, footer }) => {

  return (
    <div className="main-container">
      {/* Header */}
      <header className="main-header">
        <h1>{header}</h1>
        <nav>
          <a href="/">Home</a>
          <a href="/second">Full Screen</a>
          <a href="/contact">Contact</a>
        </nav>
      </header>

      {/* Body */}
      <div className="slide-container">
        <Fade
          autoplay
          duration={5000}
          transitionDuration={1000}
          indicators={false}
          arrows={false}
          canSwipe={true}
          pauseOnHover={false}
          easing="cubic-out"
        >
          {imageList.map((image, index) => (
            <div key={index} className="each-slide">
              <img src={image} alt={`Slide ${index}`} />
            </div>
          ))}
        </Fade>
        {/* Add other body content here as needed */}
      </div>

      {/* Footer */}
      <footer className="main-footer">
        {footer}
      </footer>
    </div>
  );
};
export default Slideshow;
